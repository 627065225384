<template>
    <div class="section-wrapper bfti-dashboard-wrapper">
        <breadcumb v-if="$route.name !== 'tea_garden_service.dashboard'"/>
        <b-overlay :show="loading">
            <div class="form-wrapper edu-trust-schol-wrapper">
                <b-card :title="$t('teaGardenDashboard.regular_garden_inspection')">
                    <b-card-text style="padding: 2rem 2rem 1rem;">
                        <b-row class="justify-content-center">
                            <b-col sm="4">
                                <router-link :to="{name: 'tea_garden_service.pdu.prepare_garden_list', params: {id: 0}, query:{ status: 2, limit: 100} }">
                                <div class="card-item-squ-col">
                                    <div class="card-item-squ">
                                        <div class="card-top schools">
                                            <i class="ri-road-map-fill"></i>
                                            <p>{{ $t('teaGardenDashboard.tot_garden_visited') }}</p>
                                        </div>
                                        <div class="card-bottom">
                                            <h3>{{ $n(visitedGarden) }}</h3>
                                        </div>
                                    </div>
                                </div>
                                </router-link>
                            </b-col>
                            <b-col sm="4">
                                <router-link :to="{name: 'tea_garden_service.pdu.prepare_garden_list', params: {id: 0}, query:{ status: 1, limit: 100} }">
                                <div class="card-item-squ-col">
                                    <div class="card-item-squ">
                                        <div class="card-top pending">
                                            <i class="ri-compass-4-fill"></i>
                                            <p>{{ $t('teaGardenDashboard.garden_visited_pending') }}</p>
                                        </div>
                                        <div class="card-bottom">
                                            <h3>{{ $n(pendingGarden) }}</h3>
                                        </div>
                                    </div>
                                </div>
                                </router-link>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </div>
        </b-overlay>
    </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import teaGardenService from '@/mixins/tea-garden-service'
export default {
    mixins: [teaGardenService],
    data () {
        return {
            teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
            visitedGarden: 0,
            pendingGarden: 0
        }
    },
    created () {
        this.loadData()
    },
    computed: {
        loading () {
            return this.$store.state.commonObj.loading
        }
    },
    methods: {
        async loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const result = await RestApi.getData(teaGardenServiceBaseUrl, 'dashboard/regular-garden-inspection')
            if (result.success) {
                if (result.data) {
                    result.data.map((item) => {
                        if (item.status === 2) {
                            this.visitedGarden = item.count
                        }
                        if (item.status === 1) {
                            this.pendingGarden = item.count
                        }
                    })
                }
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false })
        }
    }
}
</script>
